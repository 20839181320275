import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"

const About = () => {
    return(
        <Layout>
            <Title text="About" subtitle="Say what"></Title>
            <p>A community of freedom.  A community of action</p>
        </Layout>)
        
}

export default About